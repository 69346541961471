<template>
  <div class="page" id="datasource">
    <BreadCrumb/>
    <div class="page-body">
      <QueryModule :query-module="queryModule" v-on:queryRes="queryRes" v-on:reset="reset">
        <el-col :span="12">
          <el-form-item label="数据源名称">
            <el-input v-model="queryModule.dsName"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数据源描述">
            <el-input v-model="queryModule.dsDesc"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数据源类型" prop="dsType" :label-width="labelWidth">
            <el-select v-model="queryModule.dsType" placeholder="请输入数据源类型">
              <el-option
                  v-for="item in databaseType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数据源来源" prop="origin" :label-width="labelWidth">
            <el-select v-model="queryModule.origin" placeholder="请输入数据源来源">
              <el-option
                  v-for="(item, index) of sourceOfDataList"
                  :key="index"
                  :label="item"
                  :value="index"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </QueryModule>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="tree_title">
            <p class="first_class_tittle">目录分类</p>
            <div>
              <el-button circle size="mini" @click="append(0)">
                <el-icon :size="15">
                  <i-plus/>
                </el-icon>
              </el-button>
              <el-button circle size="mini" @click="addDataSource()">
                <el-icon :size="15">
                  <i-coin/>
                </el-icon>
              </el-button>
            </div>
          </div>
          <div class="tree_box border_1-dcdfe6">
            <el-tree
                :data="tree_data"
                node-key="id"
                default-expand-all
                :expand-on-click-node="false"
                :render-content="renderContent"
                @node-click="treeClick"
            />
          </div>
        </el-col>
        <el-col :span="18">
          <div class="table_title">
            <p class="first_class_tittle">数据源列表</p>
          </div>
          <div class="table_box">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column fixed prop="index" label="序号" width="50">
                <template #default="scope">
                  <span>{{
                      (currentPageNum - 1) * pageSize + scope.$index + 1
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="dsId" label="ID" width=""/>
              <el-table-column prop="dsName" label="数据库名称" width="120"/>
              <el-table-column prop="dsDesc" label="描述" width="180"/>
              <el-table-column prop="createBy" label="创建人" width="100"/>
              <el-table-column prop="type" label="类型" width="120"/>
              <el-table-column prop="createTime" label="创建时间" width="150"/>
              <el-table-column prop="updateBy" label="修改人" width="120"/>
              <el-table-column prop="updateTime" label="修改时间" width="180"/>
              <el-table-column fixed="right" label="操作" width="180">
                <template #default="scope">
                  <el-tooltip
                      class="item"
                      effect="light"
                      content="获取元数据"
                      placement="top-end"
                  >
                    <el-button
                        type="primary"
                        circle
                        size="mini"
                        @click="getSourceData(scope.row)"
                    >
                      <el-icon :size="15">
                        <i-refresh-right/>
                      </el-icon>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                      class="item"
                      effect="light"
                      content="查看库表"
                      placement="top-end"
                  >
                    <el-button
                        type="primary"
                        circle
                        size="mini"
                        @click="clickToViewLibraryTable(scope.row)"
                    >
                      <el-icon :size="15">
                        <i-folder-opened/>
                      </el-icon>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                      class="item"
                      effect="light"
                      content="修改"
                      placement="top-end"
                  >
                    <el-button
                        type="primary"
                        circle
                        size="mini"
                        @click="updataSourceData(scope.row)"
                    >
                      <el-icon :size="15">
                        <i-edit/>
                      </el-icon>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                      class="item"
                      effect="light"
                      content="删除"
                      placement="top-end"
                  >
                    <el-button
                        type="danger"
                        circle
                        size="mini"
                        @click="delentSourceData(scope.row)"
                    >
                      <el-icon :size="15">
                        <i-delete/>
                      </el-icon>
                    </el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination
              v-model:currentPage="currentPageNum"
              background
              layout="total, prev, pager, next, jumper"
              :page-size="pageSize"
              :total="totalIndex"
              @current-change="handleCurrentChange"
          />
        </el-col>
      </el-row>
    </div>
  </div>


  <!-- 新增数据源弹窗 -->
  <el-dialog v-model="dataSourceAdd" :title="dataSourceTitle">
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="数据源类型:">
        <el-select
            v-model="form.dataSourceType"
            placeholder="请选择数据源类型"
            style="width: 100%"
            @change="getSourceAddress"
        >
          <el-option
              v-for="item in databaseType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="数据源地址:">
        <el-input
            v-model="form.dataSourceAddress"
            placeholder="请输入数据源地址"
        />
      </el-form-item>
      <el-form-item label="数据源名称:">
        <el-input
            v-model="form.dataSourceName"
            placeholder="请输入数据源名称"
        />
      </el-form-item>
      <el-form-item label="数据源描述:">
        <el-input
            v-model="form.dataSourceDescription"
            placeholder="请输入数据源描述"
        />
      </el-form-item>
      <el-form-item label="用户名:">
        <el-input v-model="form.userName" placeholder="请输入用户名"/>
      </el-form-item>
      <el-form-item label="密码:">
        <el-input
            v-model="form.databasePassword"
            placeholder="请输入密码"
            show-password
        />
      </el-form-item>
      <el-form-item label="超时时间:">
        <el-input
            v-model="form.overtimeTime"
            type="number"
            autocomplete="off"
            placeholder="请输入超时时间"
        />
      </el-form-item>
      <el-form-item label="关联目录:">
        <div class="border_1px_radius">
          <el-tree
              :data="tree_data"
              show-checkbox
              node-key="id"
              default-expand-all
              check-strictly="trun"
              :default-checked-keys="associateTreeId"
              @check-change="getSourceDataTreeId"
          />
        </div>
      </el-form-item>
      <el-form-item label="编码格式:">
        <el-select
            v-model="form.encodingFormat"
            placeholder="请选择编码格式"
            style="width: 100%"
        >
          <el-option
              v-for="item in dsCharacterList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>
      <div class="button_group">
        <el-button type="primary" @click="testLink"> 测试链接</el-button>
        <el-button v-if="testResults" type="primary" @click="onSubmit">
          确 定
        </el-button>
        <el-button
            v-if="dataSourceUpdate"
            type="primary"
            @click="UpdateOnSubmit"
        >
          修 改
        </el-button>
        <el-button @click="dataSourceAdd = false"> 取消</el-button>
      </div>
    </el-form>
  </el-dialog>
  <!-- 获取数据源弹窗 -->
  <el-dialog v-model="getSourceDataDialog" :title="dataSourceTitle">
    <p>获取数据源</p>
    <div>
      <span>本地表总数:{{ dataSourceLength }}</span>
      <el-divider direction="vertical"/>
      <span>新增表总数:{{ tableAddResponses.length }}</span>
      <span>--已同步:{{ tableAddResponses.length }}</span>
      <el-divider direction="vertical"/>
      <span>修改表总数:{{ tabUpdateResponses.length }}</span>
      <span>--已同步:{{ tabUpdateResponses.length }}</span>
      <el-divider direction="vertical"/>
      <span>删除表总数:{{ tabDelResponses.length }}</span>
      <span>--已同步:{{ tabDelResponses.length }}</span>
    </div>
    <div class="getSourceDataDialog_collapse_box">
      <el-collapse accordion>
        <el-collapse-item title="新增数据源">
          <template #title>
            <div class="Responses_table_title">
              <div>
                <el-icon :size="15">
                  <i-document-add/>
                </el-icon>
              </div>
              <span>新增数据源</span>
            </div>
          </template>
          <el-collapse v-for="(item, index) of tableAddResponses" :key="index">
            <el-collapse-item>
              <template #title>
                <div class="Responses_column_title">
                  <span :title="item.tabName">本地表名:{{ item.tabName }}</span>
                  <span :title="item.tabDesc">表描述:{{ item.tabDesc }}</span>
                  <span :title="item.tabDesc">状态:{{ item.status }}</span>
                </div>
              </template>
              <div class="Responses_column_table">
                <p>修改列</p>
                <el-table :data="item.colAddResponses" style="width: 100%">
                  <el-table-column
                      prop="srcColName"
                      label="本地列名"
                      width="160"
                  />
                  <el-table-column
                      prop="srcColDatatype"
                      label="类型"
                      width="160"
                  />
                  <el-table-column
                      prop="srcColLength"
                      label="长度"
                      width="120"
                  />
                  <el-table-column prop="status" label="状态" width="160"/>
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
        <el-collapse-item>
          <template #title>
            <div class="Responses_table_title">
              <div>
                <el-icon :size="15">
                  <i-document/>
                </el-icon>
              </div>
              <span>修改数据源</span>
            </div>
          </template>
          <el-collapse v-for="(item, index) of tabUpdateResponses" :key="index">
            <el-collapse-item>
              <template #title>
                <div class="Responses_column_title">
                  <span :title="item.tabName">本地表名:{{ item.srcTabName }}</span>
                  <span :title="item.tabDesc">表描述:{{ item.srcTabDesc }}</span>
                </div>
              </template>
              <div class="Responses_table_box">
                <span>本地列总数:{{
                    item.colUpdateResponses.length +
                    item.colDelResponses.length +
                    item.colAddResponses.length
                  }}</span>
                <el-divider direction="vertical"/>
                <span>新增列总数:{{ item.colAddResponses.length }}</span>
                <span>--已同步:{{ item.colAddResponses.length }}</span>
                <el-divider direction="vertical"/>
                <span>修改列总数:{{ item.colUpdateResponses.length }}</span>
                <span>--已同步:{{ item.colUpdateResponses.length }}</span>
                <el-divider direction="vertical"/>
                <span>删除列总数:{{ item.colDelResponses.length }}</span>
                <span>--已同步:{{ item.colDelResponses.length }}</span>
              </div>
              <div class="Responses_column_table">
                <p>修改列</p>
                <el-table :data="item.colUpdateResponses" style="width: 100%">
                  <el-table-column
                      prop="newColName"
                      label="本地列名"
                      width="160"
                  />
                  <el-table-column
                      prop="newColName"
                      label="远程列名"
                      width="160"
                  />
                  <el-table-column
                      prop="isChange"
                      label="是否修改"
                      width="120"
                  />
                  <el-table-column prop="status" label="列状态" width="160"/>
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
        <el-collapse-item title="删除数据源">
          <template #title>
            <div class="Responses_table_title">
              <div>
                <el-icon :size="15">
                  <i-document-delete/>
                </el-icon>
              </div>
              <span>删除数据源</span>
            </div>
          </template>
          <el-collapse v-for="(item, index) of tabDelResponses" :key="index">
            <el-collapse-item>
              <template #title>
                <div class="Responses_column_title">
                  <span :title="item.tabName">本地表名:{{ item.tabName }}</span>
                  <span :title="item.tabDesc">表描述:{{ item.tabDesc }}</span>
                  <span :title="item.tabDesc">状态:{{ item.status }}</span>
                </div>
              </template>
              <div class="Responses_column_table">
                <p>修改列</p>
                <el-table :data="item.colDelResponses" style="width: 100%">
                  <el-table-column
                      prop="srcColName"
                      label="本地列名"
                      width="160"
                  />
                  <el-table-column
                      prop="srcColDatatype"
                      label="类型"
                      width="160"
                  />
                  <el-table-column
                      prop="srcColLength"
                      label="长度"
                      width="120"
                  />
                  <el-table-column prop="status" label="状态" width="160"/>
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
      </el-collapse>
    </div>
  </el-dialog>
  <!-- 数据库表信息弹窗 -->
  <el-dialog v-model="getDatabaseTableDialog" title="数据库表信息">
    <div class="table_box">
      <el-table
          :data="sourceTableDate"
          border
          style="width: 100%"
          @cell-dblclick="rowDblclick"
      >
        <el-table-column fixed label="序号" width="50">
          <template #default="scope">
            <span>{{ (tablePageNum - 1) * pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tabId" label="ID" width="60"/>
        <el-table-column prop="tabName" label="表名称" width="200"/>
        <el-table-column prop="tabDesc" label="描述" width="180">
          <template #default="scope">
            <span v-if="!scope.row.edit_true">{{ scope.row.tabDesc }}</span>
            <el-input
                v-if="scope.row.edit_true"
                ref="inputFocus"
                v-model="scope.row.tabDesc"
                placeholder="请输入内容"
                @blur="saveEdit(scope.row, 'table')"
                @keyup.enter="enterBlur($event)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="colSize" label="数据条数" width="100"/>
        <el-table-column fixed="right" label="操作" width="100">
          <template #default="scope">
            <el-tooltip
                class="item"
                effect="light"
                content="查看列信息"
                placement="top-end"
            >
              <el-button
                  type="primary"
                  circle
                  size="mini"
                  @click="clickTheViewColumnButton(scope.row)"
              >
                <el-icon :size="15">
                  <i-tickets/>
                </el-icon>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        v-model:currentPage="tablePageNum"
        background
        layout="total, prev, pager, next, jumper"
        :page-size="pageSize"
        :total="tableIndex"
        @current-change="tableInformationPaging"
    />
  </el-dialog>
  <!-- 数据库列信息弹窗 -->
  <el-dialog v-model="getTableDetailDialog" title="列信息">
    <div class="table_box">
      <el-table
          :data="tableDetailDate"
          border
          style="width: 100%"
          @cell-dblclick="rowDblclick"
      >
        <el-table-column label="序号" width="50">
          <template #default="scope">
            <span>{{
                (tableDetailPageNum - 1) * pageSize + scope.$index + 1
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="colId" label="ID" width="60"/>
        <el-table-column prop="colName" label="列名" width="140"/>
        <el-table-column prop="colDesc" label="描述" width="180">
          <template #default="scope">
            <span v-if="!scope.row.edit_true">{{ scope.row.colDesc }}</span>
            <el-input
                v-if="scope.row.edit_true"
                ref="inputFocus"
                v-model="scope.row.colDesc"
                placeholder="请输入内容"
                @blur="saveEdit(scope.row, 'column')"
                @keyup.enter="enterBlur($event)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="colDefault" label="默认值" width="100"/>
        <el-table-column prop="colDatatype" label="类型" width="100"/>
        <el-table-column prop="colLength" label="长度" width="100"/>
        <el-table-column prop="colNullable" label="是否为空" width="100">
          <template #default="scope">
            <p v-if="scope.row.colNullable == 1">否</p>
            <p v-else>是</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        v-model:currentPage="tableDetailPageNum"
        background
        layout="total, prev, pager, next, jumper"
        :page-size="pageSize"
        :total="tableDetailIndex"
        @current-change="columnPagination"
    />
  </el-dialog>
</template>
<script>
import QueryModule from '/src/components/QueryModule' //查询
import BreadCrumb from '/src/components/BreadCrumb' //面包屑
import {mapGetters} from 'vuex'

// let id = 1000
export default {
  name: "dataSource",
  components: {
    BreadCrumb,
    QueryModule
  },
  data() {
    return {
      inputFocuslist: [],//焦点获取顺序list
      inputFocus: '',//input焦点rel
      dataSourceTitle: '',//获取数据源的title
      dsId: 0,//数据源ID
      tableDsid: 0,//点击查看库表的库ID
      tableDetailDsid: 0,//点击查看列信息的表ID
      dataSourceLength: 0,//本地表总数
      tableAddResponses: [],//新增表总数
      tabUpdateResponses: [],//修改表总数
      tabDelResponses: [],//删除表总数
      //分页参数---------------------------------
      tablePageNum: 1,
      tableIndex: 0,
      tableDetailPageNum: 1,
      tableDetailIndex: 0,
      currentPageNum: 1,
      totalIndex: 0,
      //-----------------------------------------
      dataSourceAdd: false,//新增数据源弹窗
      dataSourceUpdate: false,//修改按钮判断
      Update: false,//测试连接时判断是修改的数据还是新增的数据
      getSourceDataDialog: false,//获取数据源弹窗
      getDatabaseTableDialog: false,//数据库表信息弹窗
      getTableDetailDialog: false,//数据库列信息弹窗
      testResults: false,//确定按钮判断
      // 查询数据源的列表信息项
      queryModule: {
        dsName: '',
        dsDesc: '',
        dsType: '',
        dsOrigin: null,
        dataDirectoryId: ''
      },
      tree_data: '',//tree信息
      tableData: [],//数据源列表信息
      sourceTableDate: [],//数据库表信息
      tableDetailDate: [],//列信息
      dsCharacterList: [],//编码格式
      dataSourceAddressList: '',//选择数据源类型联动的数据源地址
      sourceOfDataList: '',//数据源来源
      databaseType: [],//数据源类型
      associateTreeId: [],//tree选中回显
      // 添加数据源的信息项
      form: {
        dataSourceType: '',
        dataSourceAddress: '',
        dataSourceName: '',
        dataSourceDescription: '',
        userName: '',
        databasePassword: '',
        overtimeTime: 10,
        encodingFormat: '',
        treeId: ''
      }
    }
  },
  computed: {
    ...mapGetters(['pageSize'])
  },
  mounted: function () {
    this.treeData()
    this.dataOriginList(
        this.currentPageNum - 1,
        this.pageSize,
        this.queryModule
    )
  },

  methods: {
    //按enter失去焦点==================================================================================================================
    enterBlur(event) {
      event.target.blur()
    },
    //数据库表信息inuput失去焦点时保存==================================================================================================================
    saveEdit(row, type) {
      row.edit_true = false
      if (type == 'table') {
        this.databaseTableUpdate(row)
      } else {
        this.columnInformationUpdate(row)
      }
    },
    //控制‘数据库表信息弹窗’双击切换出现input==================================================================================================================
    rowDblclick(row) {
      //判断是否是需要编辑的列 再改变对应的值
      row.edit_true = true
      //获取input焦点
      this.inputFocuslist.push(this.inputFocus)
      this.inputFocus = ''
      this.$nextTick(() => {
        this.$refs.inputFocus.focus()
      })
    },
    //点击查看库表按钮==================================================================================================================
    clickToViewLibraryTable(data) {
      this.tablePageNum = 1
      this.tableDsid = data.dsId
      this.getDatabaseTable()
      this.getDatabaseTableDialog = true
    },
    //获取数据库的表信息接口==================================================================================================================
    getDatabaseTable() {
      let datas = {
        page: this.tablePageNum - 1,
        size: this.pageSize,
        dsId: this.tableDsid
      }
      this.$axios
          .POST('/api/v1/core/project/datasource/all/tab', datas)
          .then(res => {
            if (res.code == 200) {
              $.each(res.data.content, function () {
                this['edit_true'] = false
              })
              this.sourceTableDate = res.data.content
              this.tableIndex = res.data.totalElements
            } else {
              this.$message({
                type: 'info',
                message: '获取库表信息失败!'
              })
            }
          })
    },

    //修改表信息接口==================================================================================================================
    databaseTableUpdate(row) {
      let datas = {
        dsId: row.tabId,
        tabNmae: row.tabName,
        tabDesc: row.tabDesc
      }
      this.$axios
          .PUT('/api/v1/core/project/datasource/upDataTabDesc', datas)
          .then(res => {
            console.log(res)
          })
    },
    //点击查看列按钮==================================================================================================================
    clickTheViewColumnButton(data) {
      this.tableDetailPageNum = 1
      this.tableDetailDsid = data.tabId
      this.getTableDetail()
      this.getTableDetailDialog = true
    },
    //获取表详情==================================================================================================================
    getTableDetail() {
      let datas = {
        page: this.tableDetailPageNum - 1,
        size: this.pageSize,
        tabId: this.tableDetailDsid
      }
      this.$axios
          .POST('/api/v1/core/project/datasource/all/col', datas)
          .then(res => {
            if (res.code == 200) {
              $.each(res.data.content, function () {
                this['edit_true'] = false
              })
              this.tableDetailDate = res.data.content
              this.tableDetailIndex = res.data.totalElements
            } else {
              this.$message({
                type: 'info',
                message: '获取库表信息失败!'
              })
            }
          })
    },

    //修改表列信息==================================================================================================================
    columnInformationUpdate(row) {
      let datas = {
        tabId: row.colId,
        colName: row.colName,
        colDesc: row.colDesc
      }
      this.$axios
          .PUT('/api/v1/core/project/datasource/upDataColDesc', datas)
          .then(res => {
            console.log(res)
          })
    },
    //添加和修改弹窗内获取treeID==================================================================================================================
    getSourceDataTreeId(data) {
      this.associateTreeId.push(data.id)
      this.form.treeId = data.id
    },
    //测试链接==================================================================================================================
    testLink() {
      let datas = {
        timeout: this.form.overtimeTime * 1,
        dsType: this.form.dataSourceType * 1,
        dsName: this.form.dataSourceName,
        dsDesc: this.form.dataSourceDescription,
        dsUrl: this.form.dataSourceAddress,
        dsUsername: this.form.userName,
        dsPassword: this.form.databasePassword,
        dsCharacterSet: this.form.encodingFormat * 1,
        dataDirectoryId: this.form.treeId
      }
      this.$axios
          .POST('/api/v1/core/project/datasource/testConn', datas)
          .then(res => {
            if (res.code == 200) {
              if (this.Update) {
                this.dataSourceUpdate = res.data
              } else {
                this.testResults = res.data
              }
              this.$message({
                type: 'success',
                message: '测试成功!'
              })
            } else {
              this.$message({
                type: 'info',
                message: '测试失败!'
              })
            }
          })
    },
    //新增数据源信息==================================================================================================================
    onSubmit() {
      let datas = {
        timeout: this.form.overtimeTime * 1,
        dsType: this.form.dataSourceType * 1,
        dsName: this.form.dataSourceName,
        dsDesc: this.form.dataSourceDescription,
        dsUrl: this.form.dataSourceAddress,
        dsUsername: this.form.userName,
        dsPassword: this.form.databasePassword,
        dsCharacterSet: this.form.encodingFormat * 1,
        dataDirectoryId: this.form.treeId
      }
      this.$axios
          .PUT('/api/v1/core/project/datasource/add', datas)
          .then(res => {
            if (res.code == 200) {
              this.dataSourceAdd = false
              this.$message({
                type: 'success',
                message: '添加成功!'
              })
              this.dataOriginList(
                  this.currentPageNum - 1,
                  this.pageSize,
                  this.queryModule
              )
            } else if (res.code == 400) {
              this.$message({
                type: 'info',
                message: res.message
              })
            }
          })
    },
    //修改数据源信息==================================================================================================================
    UpdateOnSubmit() {
      let datas = {
        dsId: this.dsId,
        timeout: this.form.overtimeTime * 1,
        dsType: this.form.dataSourceType * 1,
        dsName: this.form.dataSourceName,
        dsDesc: this.form.dataSourceDescription,
        dsUrl: this.form.dataSourceAddress,
        dsUsername: this.form.userName,
        dsPassword: this.form.databasePassword,
        dsCharacterSet: this.form.encodingFormat * 1,
        dataDirectoryId: this.form.treeId
      }
      this.$axios
          .PUT('/api/v1/core/project/datasource/add', datas)
          .then(res => {
            if (res.code == 200) {
              this.dataSourceAdd = false
              this.$message({
                type: 'success',
                message: '添加成功!'
              })
              this.dataOriginList(
                  this.currentPageNum - 1,
                  this.pageSize,
                  this.queryModule
              )
            } else if (res.code == 400) {
              this.$message({
                type: 'info',
                message: res.message
              })
            }
          })
    },
    //数据源类型下拉选联动事件==================================================================================================================
    getSourceAddress(data) {
      $.each(this.dataSourceAddressList, (key, value) => {
        if (key == data) {
          this.form.dataSourceAddress = value
        }
      })
    },
    //分页方法=================================================================================================================================
    handleCurrentChange() {
      this.dataOriginList(
          this.currentPageNum - 1,
          this.pageSize,
          this.queryModule
      )
    },
    columnPagination() {
      this.getTableDetail()
    },
    tableInformationPaging() {
      this.getDatabaseTable()
    },
    //获取元数据按钮==================================================================================================================
    getSourceData(row) {
      this.getSourceDataDialog = true
      this.dataSourceTitle = '获取数据源'
      this.$axios
          .POST('/api/v1/core/project/datasource/metadata/' + row.dsId)
          .then(res => {
            // this.tableDatas = res.data
            this.tabDelResponses = res.data.tabDelResponses
            this.tabUpdateResponses = res.data.tabUpdateResponses
            this.tableAddResponses = res.data.tableAddResponses
            // this.colUpdateResponses =res.data.tabUpdateResponses.colUpdateResponses
            this.dataSourceLength =
                this.tabUpdateResponses.length +
                this.tabDelResponses.length +
                this.tableAddResponses.length
          })
    },
    //修改数据源按钮==================================================================================================================
    updataSourceData(row) {
      this.$axios
          .GET('/api/v1/core/project/datasource/' + row.dsId)
          .then(res => {
            this.dsId = res.data.dsId
            this.form.dataSourceType = res.data.dsType + ''
            this.form.dataSourceAddress = res.data.dsUrl
            this.form.dataSourceName = res.data.dsName
            this.form.dataSourceDescription = res.data.dsDesc
            this.form.userName = res.data.dsUsername
            this.form.databasePassword = res.data.dsPassword
            this.form.overtimeTime = 10
            this.form.encodingFormat = res.data.dsCharacterSet + ''
            this.form.treeId = res.data.dataDirectoryId
            this.associateTreeId = []
            this.associateTreeId.push(res.data.dataDirectoryId)
          })
      this.Update = true
      this.dataSourceTitle = '修改数据源'
      this.dataSourceAdd = true
    },
    //删除数据源按钮==================================================================================================================
    delentSourceData(row) {
      this.$confirm('确定删除序号为' + row.dsId + '的数据源信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.$axios
                .DELETE('/api/v1/core/project/datasource/' + row.dsId)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: '删除成功!'
                    })
                    this.dataOriginList(
                        this.currentPageNum - 1,
                        this.pageSize,
                        this.queryModule
                    )
                  }
                })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
    },
    //获取数据源列表table数据=========================================================================================================
    dataOriginList(page, pageSize, datas) {
      this.$axios
          .POST('/api/v1/core/project/datasource/all', {
            page: page,
            size: pageSize,
            dsName: datas.dsName,
            dsDesc: datas.dsDesc,
            dsType: datas.dsType,
            dsOrigin: datas.origin,
            dataDirectoryId: datas.dataDirectoryId
          })
          .then(res => {
            this.tableData = res.data.content
            this.totalIndex = res.data.totalElements
          })
      //获取数据源类型
      this.$axios
          .GET('/api/v1/core/project/datasource/selected/get_ds_type_value')
          .then(res => {
            this.databaseType = []
            $.each(res.data, (key, value) => {
              const dropDownFrame = {value: '', label: ''}
              dropDownFrame.value = key
              dropDownFrame.label = value
              this.databaseType.push(dropDownFrame)
            })
          })
      //获取编码格式
      this.$axios
          .GET('/api/v1/core/project/datasource/selected/get_ds_coding_value')
          .then(res => {
            this.dsCharacterList = []
            $.each(res.data, (key, value) => {
              const dropDownFrame = {value: '', label: ''}
              dropDownFrame.value = key
              dropDownFrame.label = value
              this.dsCharacterList.push(dropDownFrame)
            })
          })
      //获取格式URL
      this.$axios
          .GET('/api/v1/core/project/datasource/selected/get_ds_url_value')
          .then(res => {
            this.dataSourceAddressList = ''
            this.dataSourceAddressList = res.data
          })
      //数据源来源
      this.$axios
          .GET("/api/v1/core/project/datasource/selected/get_ds_origin_value")
          .then((res) => {
            this.dataSourceAddressList = ''
            this.sourceOfDataList = res.data
          })
    },

    //获取tree数据==================================================================================================================
    treeData() {
      this.$axios.GET('/api/v1/core/project/data_directory/all').then(res => {
        let data = res
        if (data.code == 200) {
          this.tree_data = JSON.parse(JSON.stringify(data.data))
        }
      })
    },
    //tree重命名节点==================================================================================================================
    rename(data) {
      this.$prompt('请输入新的节点名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\u4e00-\u9fa5]{0,}$/,
        inputErrorMessage: '请输入中文节点名称',
        inputValue: data.label
      })
          .then(({value}) => {

            const rootNodeData = {
              id: data.id,
              dataDirectoryName: value,
              isDisplay: 0,
              isOpen: 0,
              sort: 0,
              parentId: 0
            }
            this.$axios
                .PUT('/api/v1/core/project/data_directory/add', rootNodeData)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: '修改' + data.label + '节点为 ' + value + '成功！'
                    })
                    data.label = value
                  } else {
                    this.$message({
                      type: 'info',
                      message: '修改' + data.label + '节点失败！'
                    })
                  }
                })
          })
    },
    //tree添加节点==================================================================================================================
    append(data) {
      if (data == 0) {
        //添加根节点
        this.$prompt('请输入添加的根节点名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[\u4e00-\u9fa5]{0,}$/,
          inputErrorMessage: '请输入中文节点名称'
        })
            .then(({value}) => {
              const rootNodeData = {
                id: 0,
                dataDirectoryName: value,
                isDisplay: 0,
                isOpen: 0,
                sort: 0,
                parentId: 0
              }
              this.$axios
                  .PUT('/api/v1/core/project/data_directory/add', rootNodeData)
                  .then(res => {
                    if (res.data != 0) {
                      const newRootNode = {
                        id: res.data,
                        label: value,
                        children: []
                      }
                      this.tree_data.push(newRootNode)
                      this.$message({
                        type: 'success',
                        message: '新增根节点: ' + value
                      })
                    } else if (res.data == 0) {
                      this.$message({
                        type: 'info',
                        message: '添加节点失败'
                      })
                    }
                  })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '取消添加节点'
              })
            })
      } else {
        //添加子节点
        this.$prompt('请输入添加的子节点名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[\u4e00-\u9fa5]{0,}$/,
          inputErrorMessage: '请输入中文节点名称'
        })
            .then(({value}) => {
              //如果没有子节点添加空子节点
              if (!data.children) {
                data.children = []
              }
              const childNodeData = {
                id: 0,
                dataDirectoryName: value,
                isDisplay: 0,
                isOpen: 0,
                sort: 0,
                parentId: data.id
              }
              this.$axios
                  .PUT('/api/v1/core/project/data_directory/add', childNodeData)
                  .then(res => {
                    //0为后台数据插入失败
                    if (res.data != 0) {
                      const newChild = {
                        id: res.data,
                        label: value,
                        children: []
                      }
                      data.children.push(newChild)
                      this.$message({
                        type: 'success',
                        message: '新增子节点: ' + value
                      })
                    } else if (res.data == 0) {
                      this.$message({
                        type: 'info',
                        message: '添加子节点失败'
                      })
                    }
                  })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '取消添加节点'
              })
            })
      }
    },
    //tree删除子节点==================================================================================================================
    remove(node, data) {
      this.$confirm('此操作将永久删除该节点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            const parent = node.parent
            const children = parent.data.children || parent.data
            const index = children.findIndex(d => d.id === data.id)
            children.splice(index, 1)
            this.$axios
                .DELETE('/api/v1/core/project/data_directory/delete/' + data.label)
                .then(res => {
                  console.log(res)
                })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
    },
    //添加数据源按钮==================================================================================================================
    addDataSource() {
      this.dataSourceTitle = '添加数据源'
      this.dataSourceAdd = true
      this.form.dataSourceType = ''
      this.form.dataSourceAddress = ''
      this.form.dataSourceName = ''
      this.form.dataSourceDescription = ''
      this.form.userName = ''
      this.form.databasePassword = ''
      this.form.overtimeTime = 10
      this.form.encodingFormat = ''
    },
    //tree节点点击==================================================================================================================
    treeClick(data) {
      this.queryModule.dataDirectoryId = ''
      this.queryModule.dataDirectoryId = data.id * 1
      this.dataOriginList(
          this.currentPageNum - 1,
          this.pageSize,
          this.queryModule
      )
    },
    //tree操作节点renderContent方法==================================================================================================================
    renderContent(
        h,
        {
          node,
          data
          // store
        }
    ) {
      return h(
          'span',
          {
            class: 'custom-tree-node'
          },
          h('span', {onClick: () => this.treeClick(data)}, null, node.label),
          h(
              'span',
              {class: 'd-flex-justify-center'},
              null,
              h(
                  'a',
                  {
                    class: 'tree_rename_button',
                    onClick: () => this.rename(data),
                    style: {
                      margin: '5px',
                      color: '#409EFF'
                    }
                  },
                  ''
              ),
              h(
                  'a',
                  {
                    class: 'tree_append_button',
                    onClick: () => this.append(data),
                    style: {
                      margin: '5px',
                      color: '#409EFF'
                    }
                  },
                  ''
              ),
              h(
                  'a',
                  {
                    class: 'tree_remove_button',
                    onClick: () => this.remove(node, data),
                    style: {
                      margin: '10px',
                      color: '#909399'
                    }
                  },
                  ''
              )
          )
      )
    },
    //搜索查询
    queryRes() {
      this.dataOriginList(
          this.currentPageNum - 1,
          this.pageSize,
          this.queryModule
      )
    },
    //搜索内容重置
    reset() {
      this.queryModule.dsName = ''
      this.queryModule.dsDesc = ''
      this.queryModule.dsType = ''
      this.queryModule.dsOrigin = ''
      this.queryModule.dataDirectoryId = ''
      this.dataOriginList(
          this.currentPageNum - 1,
          this.pageSize,
          this.queryModule
      )
    }
  }
}
</script>

<style scoped>
.button_group {
  width: 100%;
  display: flex;
  justify-content: center;
}

.getSourceDataDialog_collapse_box {
  margin-top: 20px;
}

.Responses_column_title {
  display: flex;
  justify-content: start;
  width: 100%;
  margin-left: 30px;
}

.Responses_column_title > span {
  width: 50%;
  color: #606266;
}

.Responses_table_title {
  display: flex;
  justify-content: start;
  width: 100%;
  /* background-color: #f2f6fc; */
}

.Responses_table_title > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Responses_table_title > span {
  font-size: 16px !important;
  margin-left: 10px;
}

.Responses_table_box {
  margin-left: 30px;
  color: #606266;
}

.Responses_column_table {
  margin: 10px 30px 0px 30px;
}

.Responses_column_table > p {
  width: 100%;
  line-height: 30px;
  text-align: center;
  background-color: #c0c4cc29;
}



</style>
